import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout.jsx";
import RetailStyles from "ps-assets/jss/buyHealthInsuranceOnlineStyle.jsx";
import PropTypes from "prop-types";
import logo from "ps-assets/img/health_insurance_logo.png";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import peoplebanner from "ps-assets/img/people.png";
// import mainbanner from "ps-assets/img/health_insurance_main_blue_bg.png";
import staricons from "ps-assets/img/staricons.png";
import root from "window-or-global";
import Checkbox from "@material-ui/core/Checkbox";
import { landingPageLMSCall } from "util/lmsRequest";
import {  browserWindow } from "util/localStorageHelper";
// import Grid from '../../../AppData/Local/Microsoft/TypeScript/4.2/node_modules/@material-ui/core/Grid/Grid';
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  phone: `^[6-9][0-9]{9}$`,
  email: `^[a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$`,
  pincode: `[1-9][0-9]{5}$`,
};

const whystar = [
  '89.9% of all cashless claim approval done within 1 hour',
  'Largest network hospitals base of over  '+HOSPITALCOUNT+' hospitals',
  'Hassle free and customer friendly direct claim settlement without intervention of TPA',
  'Rs. 30,385 Crs claim amount paid out since inception',
  'Upto 100% increase in sum insured upon a claim free renewal',
];
class BuyHealthInsuranceOnline extends Component {
  state = {
    check: false,
    user: {
      name: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please Enter Name`,
        error1: `Please Enter Name`,
        error2: `Name should be minimum 3 and maximum 50 character`,
      },
      email: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter correct Email ID and try again.`,
        error1: `Please enter correct Email ID and try again.`,
      },
      phone: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter valid Phone Number`,
        error1: `Please enter valid Phone Number`,
        error2: `Phone Number should be minimum 10 Digits`,
      },

      pincode: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter valid PinCode`,
        error1: `Please enter valid PinCode`,
        error2: `PinCode should be minimum 6 Digits`,
      },
    },
  };
  basestate = {
    user: this.state.user,
  };
  handleChange(e) {
    let change = {};
    change = JSON.parse(JSON.stringify(this.state.user));
    if (e.target.name == `name`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (e.target.value.length < 3) {
          change[e.target.name].errormsg = change[e.target.name].error2;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^A-Za-z ]/gi, ``);
        change[e.target.name].value = value;
      }
    } else if (e.target.name == `email`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        change[e.target.name].value = e.target.value;
      }
    } else if (e.target.name == `phone`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          change[e.target.name].errormsg = change[e.target.name].error2;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^0-9]/gi, ``);
        change[e.target.name].value = value;
      }
    } else if (e.target.name == `pincode`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          if(e.target.value[0]=='0'){
            change[e.target.name].errormsg = change[e.target.name].error1;
            change[e.target.name].error = true;
          }else{
            change[e.target.name].errormsg = change[e.target.name].error2;
            change[e.target.name].error = true;
          }
          
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^0-9]/gi, ``);
        change[e.target.name].value = value;
      }
    }
    this.setState({
      user: change,
    });
  }
  handleCheck = () => {
    let check1 = !this.state.check;
    this.setState({
      check: check1,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    let error = false;

    Object.keys(this.state.user).map((key) => {
      if (this.state.user[key].error && !error) {
        alert(this.state.user[key].errormsg);
        error = true;
        return;
      }
    });
    if (this.state.check == false && error == false) {
      //   alert(`Read Terms and Conditions`);
      error = true;
      return;
    }
    if (error == false) {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.user.name.value,
        this.state.user.phone.value,
        this.state.user.email.value,
        this.state.user.pincode.value
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, `free-health-quote`);
      root.open(
        `${browserWindow.location.pathname}/thank-you?userId=${response.zohoEntityId}${
          queryParams ? `&${queryParams}` : ``
        }`,
        `_self`
      );
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <InstantBuyLayout noFooter={true} noHeader={true}>
        <GridContainer
          className={classes.pagePadding}
          style={{ borderBottom: "1px solid #24386F" }}
        >
          <GridItem
            className={classes.logoposition}
          >
            <img src={logo} />
          </GridItem>
          <GridContainer
            className={classes.Paddingposition}
          >
            <GridItem
              md={7}
              lg={7}
              xs={12}
              sm={12}
              className={classes.marginrightbanner}
            >
              <div className={classes.bannerposition}>
                <div className={classes.title1}>
                  When It Comes To Health, Trust Only The Experts!
                </div>
                <div className={classes.title2}>
                  Buy Star Health Insurance Policy Today and Secure Your Family
                </div>
                <form
                  onSubmit={this.handleSubmit}
                  style={{ width: "100%", height: "100%", marginTop: `2%` }}
                >
                  <div className={classes.formdivstyle}>
                    <GridContainer>
                      <GridItem
                        className={classes.hiddenDisplay}
                      >
                        <div style={{ fontSize: "12.5px", marginTop: "10px" ,fontWeight:'500' }}>
                          Please Fill in Your Details to Get a Free Quote
                        </div>
                      </GridItem>
                      <GridItem md={8} lg={8} xs={12} sm={8}>
                        <input
                          type="text"
                          className={classes.inputstyle}
                          name="name"
                          placeholder="Name"
                          value={this.state.user.name.value}
                          onChange={this.handleChange.bind(this)}
                        />
                      </GridItem>
                      <GridItem md={4} lg={4} xs={12} sm={4}>
                        <input
                          type="text"
                          className={classes.mobileNoPosition}
                          name="phone"
                          placeholder="Mobile Number"
                          value={this.state.user.phone.value}
                          onChange={this.handleChange.bind(this)}
                          maxLength="10"
                        />
                      </GridItem>
                      <GridItem md={8} lg={8} xs={12} sm={8}>
                        <input
                          type="text"
                          className={classes.inputstyle}
                          name="email"
                          placeholder="Email ID"
                          value={this.state.user.email.value}
                          onChange={this.handleChange.bind(this)}
                        />
                      </GridItem>
                      <GridItem md={4} lg={4} xs={12} sm={4}>
                        <input
                          type="text"
                          className={classes.mobileNoPosition}
                          name="pincode"
                          placeholder="PinCode"
                          value={this.state.user.pincode.value}
                          onChange={this.handleChange.bind(this)}
                        />
                      </GridItem>
                      <GridItem
                        style={{ paddingBottom: "0px", paddingTop: "0px" }}
                      >
                        <GridContainer className={classes.privacyPosition}>
                         <GridItem sm={1} xs={1} style={{paddingRight:'0px'}}>  <Checkbox
                            checked={this.state.check}
                            style={{
                              color: "#053D7D",
                              padding: "0px",
                            }}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            onClick={this.handleCheck}
                          /></GridItem>
                        <GridItem sm={11} xs={11} className={classes.PrivacyGrid}>
                          <span style={{fontSize:'11px',fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "400",opacity: "1",}}>
                          I have read and agree to the{" "}
                          <a
                            style={{
                              cursor: `pointer`,
                              color: "#337ab7",
                            }}
                            href="/static-privacy-policy"
                            target="_blank"
                            ref={`noopener noreferrer`}
                          >
                            Privacy Policy.
                          </a>{" "}
                          
                          I authorize Star Health And Allied Insurance to contact me via email, phone or SMS.</span>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem
                        className={classes.submitPosition}
                      >
                        <input type="submit" className={classes.submitbtm} />
                      </GridItem>
                    </GridContainer>
                  </div>
                </form>
              </div>
            </GridItem>
            <GridItem
              md={6}
              lg={6}
              xs={12}
              sm={12}
                className={classes.peroplebanner1}
              
            >
              <img src={peoplebanner} className={classes.peroplebanner} style={{height: 'auto'}} />
            </GridItem>
          </GridContainer>
        </GridContainer>
        <GridContainer
          className={classes.Padding}
          style={{ background: "#113F74", marginTop: "1px" }}
        >
          <GridItem md={12} lg={12} xs={12} sm={12}>
            <div className={classes.familybanner}>
              <div className={classes.listposition}>
                <div className={classes.listheaderstyle}>
                  Why Star Health Insurance?
                </div>
                <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                  {whystar.map((item, index) => (
                    <li key={index} style={{ padding: "6px 0px", lineHeight:'1.42857143',}}>
                      <img src={staricons} className={classes.iconPadding} />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <div className={classes.footer}>
          <div>
            REGISTERED & CORPORATE OFFICE: NO: 1, NEW TANK STREET,
            VALLUVARKOTTAM HIGH ROAD, NUNGAMBAKKAM, CHENNAI - 600034. Sales & Services - 044 4674 5800
            : 
          </div>
          <div>CIN NO: L66010TN2005PLC056649.</div>
          <div>IRDAI REGISTRATION NO: 129.</div>
          <div>INSURANCE IS THE SUBJECT MATTER OF SOLICITATION.</div>
          <div>
            FOR MORE DETAILS ON RISK FACTORS, TERMS AND CONDITIONS PLEASE READ
            THE SALES BROCHURE CAREFULLY BEFORE CONCLUDING A SALE.
          </div>
          <div style={{ marginTop: "25px" }}>
            IRDAI CLARIFIES TO PUBLIC THAT * IRDAI OR ITS OFFICIALS DO NOT
            INVOLVE IN ACTIVITIES LIKE SALE OF ANY KIND OF INSURANCE OR
            FINANCIAL PRODUCTS NOR INVEST PREMIUMS.
          </div>
          <div>* IRDAI DOES NOT ANNOUNCE ANY BONUS.</div>
          <div>
            * PUBLIC RECEIVING SUCH PHONE CALLS ARE REQUESTED TO LODGE A POLICE
            COMPLAINT ALONG WITH DETAILS OF PHONE CALL, NUMBER.
          </div>
          <br />
          <div>
            For further details visit www.starhealth.in |
            SHAI/17-18/Online/LP/BuyOnline/006
          </div>
        </div>
      </InstantBuyLayout>
    );
  }
}

BuyHealthInsuranceOnline.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(RetailStyles)(BuyHealthInsuranceOnline);
