import cloudbanner from "ps-assets/img/cloud.png";
import skymobile from "ps-assets/img/sky-mobile.png";
import familybanner from "ps-assets/img/family.png";
const buyHealthInsuranceOnlineStyle = function() {
  return {
    inputdiv: {
      width: " 65%",
      float: "right",
      textAlign: "left",
      marginBottom: "-4px",
    },
    inputstyle: {
      border: "1px solid skyblue",
      marginBottom: "5px",
      width: " 100%",
      padding: "0px 25px",
      // marginTop:'1.5%',
    
      background:'white',
      fontSize: "12px !important",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      height: "38px",
      "@media (max-width: 780px)": {
        padding:'0px',
        width:'97.5%',
        paddingLeft:'10px',
        "&,&::placeholder": {
          // fontSize: "14px",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          // fontWeight: "400",
          // lineHeight: "1.42857",
          opacity: "1",
          color:'black'
        },
       },
       
    },
    selectinputstyle: {
      border: "1px solid skyblue",
      marginBottom: "5px",
      width: " 100%",
      padding: "0px 25px",
      // marginTop:'1.5%',
    
      background:'white',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "12px !important",
      height: "38px",
      
      "@media (max-width: 780px)": {
        padding:'0px',
        paddingLeft:'7px',
        width:'97.5%',
         "&,&::placeholder": {
          // fontSize: "14px",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          // fontWeight: "400",
          // lineHeight: "1.42857",
          opacity: "1",
          color:'black'
        },
       },
      
    },
    Paddingposition:{
      width: "100%",
    height: "100%",
    background: "linear-gradient(#89D4FF, white) 0% 0% / 100% 100% repeat-x",
    "@media (max-width: 959px)": {
      paddingLeft:'2%',
      paddingRight: '2%',
      background: "linear-gradient(#89D4FF, white) 0% 0% / 100% 100% repeat-x",
    },
    "@media (max-width: 500px)": {
      paddingLeft:'0%',
      paddingRight: '0%',
      width: "100% !important",
      background: "linear-gradient(#89D4FF, white) 0% 0% / 100% 62% repeat-x",
    },
      "@media (max-width: 450px)": {
        background: "linear-gradient(#89D4FF, white) 0% 0% / 100% 67% repeat-x",
        width: "100% !important",
        marginLeft: "0px !important",
      },
      "@media (max-width: 350px)": {
        background: "linear-gradient(#89D4FF, white) 0% 0% / 100% 72% repeat-x",
        width: "100% !important",
        marginLeft: "0px !important",
      },
    
        },
    mobileNoPosition:{
    padding: "0px 15px",
    border: "1px solid skyblue",
      marginBottom: "5px",
      width: " 100%",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      // marginTop:'1.5%',
    
      background:'white',
      fontSize: "12px !important",
      height: "38px",
      
      "@media (max-width: 780px)": {
        padding:'0px',
        paddingLeft:'10px',
        width:'97.5%',
        "&,&::placeholder": {
          // fontSize: "14px",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          // fontWeight: "400",
          // lineHeight: "1.42857",
          opacity: "1",
          color:'black'
        },
       },
       
    },
    privacyPosition:{
    width: '80.3333333%',
    fontSize: "12.5px", 
    marginTop: "10px",
    color:'#666666',
    "@media (max-width: 780px)": {
      width: '100%',
     },
    },
    PrivacyGrid:{    
      lineHeight:'normal',
      paddingRight:'0px !important',
      "@media (max-width: 770px)": {
        marginLeft: '-4%',
        marginTop: '0.5%'
       },
       "@media (max-width: 542px)": {
        marginLeft: '-2%',
        marginTop: '0.5%'
       },
       "@media (max-width: 538px)": {
        marginLeft: '-4%',
        marginTop: '0.5%'
       },
      "@media (max-width: 450px)": {
        paddingLeft: '35px'
       },
      "@media (max-width: 400px)": {
        paddingLeft: '35px'
       },
      
    },
    submitbtm: {
      fontSize: "15px",
      backgroundColor: "#04366e",
      color: "#fff",
      cursor: "pointer",
      borderRadius: "10px",
      border: "none",
      outline: "none",
      fontWeight: "500",
      padding: "4px 15px",

      "@media (max-width: 780px)": {
        fontSize: '15px',
      marginTop: '19px',
      backgroundColor: '#053D7D !important',
      color: '#fff !important',
      borderRadius: '10px',
      border: 'none !important',
      outline: 'none !important',
      fontWeight: '550',
      padding: '6px 15px'
       },
      
    },
    peroplebanner1: {
      "@media (max-width: 650px)": {
       padding:'0px !important',
      },
    },

    peroplebanner: {
      backgroundImage: `url(${cloudbanner})`,
      //   marginTop: '5%',
      paddingTop: "14%",
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      "@media (max-width: 1126px)": {
        marginTop: "24%",
        backgroundSize: '100% 87%',
        backgroundRepeat: 'no-repeat',        
      },
      "@media (max-width: 959px)": {
        backgroundImage: `url(${skymobile})`,
        marginTop: "25%",
        backgroundSize: '100% 87%',
        backgroundRepeat: 'no-repeat',
      },
      "@media (max-width: 813px)": {
        backgroundImage: `url(${skymobile})`,
        marginTop: "58%",
        backgroundSize: '100% 87%',
        backgroundRepeat: 'no-repeat',
      },
      "@media (max-width: 770px)": {
        backgroundImage: `url(${skymobile})`,
        marginTop: "28%",
        backgroundSize: '100% 87%',
        backgroundRepeat: 'no-repeat',
      },
      "@media (max-width: 760px)": {
        backgroundImage: `url(${skymobile})`,
        marginTop: "58%",
        backgroundSize: '100% 87%',
        backgroundRepeat: 'no-repeat',
      },
      "@media (max-width: 530px)": {
        backgroundImage: `url(${skymobile})`,
        marginTop: "83%",
        backgroundSize: '100% 87%',
        backgroundRepeat: 'no-repeat',
        paddingTop: "28%",
        background:'white'
      },
      "@media (max-width: 400px)": {
        backgroundImage: `url(${skymobile})`,
        marginTop: "100%",
        backgroundSize: '100% 78%',
        backgroundRepeat: 'no-repeat',
        paddingTop: "28%",
        background:'white'
      },
      "@media (max-width: 325px)": {
        backgroundImage: `url(${skymobile})`,
        marginTop: "107%",
        backgroundSize: '100% 78%',
        backgroundRepeat: 'no-repeat',
        paddingTop: "28%",
        background:'white'
      },

      
    },
    bannerposition: {
      width: "80%",
      marginTop: "2.5%",
      marginLeft: "20%",
      "@media (max-width: 959px)": {
        marginLeft: "3.5%",
        marginRight: "3.5%",
        height: "100%",
      },
      "@media (max-width: 450px)": {
        width:'100%',
        marginLeft: "7.5%",
        paddingRight: "2%",
        height: "100%",
      },
    },
    pagePadding: {
      "@media (max-width: 450px)": {
        marginLeft: "0px !important",
        marginRight: "0px !important",
      },
    },
    marginrightbanner: {
      marginRight: "-10%",
      paddingBottom: "5%",
      width: "100%",
      "@media (max-width: 959px)": {
        marginRight: "6.5% !important",
      },
      "@media (max-width: 450px)": {
        marginLeft: "-16px !important",
      },
    },
    logoposition:{
    width: "100%",
              paddingLeft: "9%",
              paddingBottom: "0.8%",
              paddingTop: "0.3%",
              background: "white",
              borderBottom: "1px solid #04366e",
              "@media (max-width: 770px)": {
                paddingBottom: "0.8%",
              },
              "@media (max-width: 450px)": {
                paddingLeft: "3%",
              paddingBottom: "2.5%",
              },
              
    },
    title1: {
      color: "#04366e",
      fontWeight: "500",
      fontSize: "16px",
      marginBottom: "0px",
      marginTop: "10px",
      "@media (max-width: 550px)": {
        marginTop:'6.2%',
        fontSize: "12px",
        textAlign:'center'
      },
    },

    title2: {
      color: "#04366e",
      fontWeight: "600",
      fontSize: "24px",
      marginTop: "0px",
      lineHeight: "29px",
      paddingRight: "25px",
      "@media (max-width: 550px)": {
      fontSize: "18px",
      lineHeight: "23px",
      color:'#04366e !important',
      fontWeight: "600",
      textAlign:'center',
      paddingRight:'0px'
      },
      
    },
    submitPosition:{
    textAlign: "right", paddingTop: "0px",
    "@media (max-width: 780px)": {
     paddingRight:'27px '
    },
    },
    formdivstyle: {
      background: "#ffffff",
      padding: "15px",
      position: "absolute",
      zIndex: "10",
      "@media (max-width: 959px)": {
        width: "94%",
      },
      "@media (max-width: 780px)": {
        width: "100%",
      },
      "@media (max-width: 450px)": {
        width: "93%",
        marginLeft: "-6px",
        background: "none",
      },
    },
    Padding: {
      "@media (max-width: 450px)": {
        width: "100% !important",
        marginLeft: "0px !important",
      },
    },
    familybanner: {
      marginLeft: "10%",
      backgroundImage: `url(${familybanner})`,
      zIndex: "10",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left bottom",
      minHeight: "310px",
      "@media (max-width: 992px)": {
        backgroundImage: `none`,
        marginLeft: "0%",
        minHeight:'100%'
      },
    },
    listheaderstyle: {
      color: "#ffffff",
      fontSize: "21px",
      fontFamily: "inherit",
      fontWeight: "500",
      marginTop: "10px",
      marginBottom: "10px",
    },
    listposition: {
      marginLeft: "35%",
      paddingTop: "3%",
      paddingBottom: "2%",
      color: "#89D4FF",
      fontSize: "11px",
      fontWeight: "500",
      "@media (max-width: 1295px)": {
        marginLeft: "47%",
      },
      "@media (max-width: 992px)": {
        marginLeft: "1.5%",
        fontWeight: "400",
      },
    },
    iconPadding:{
    paddingRight: "1%",
    marginTop:'-3px' ,
    "@media (max-width: 770px)": {
      paddingRight: "2.5%",
      
    },
    },
    footer: {
      paddingTop: `2%`,
      paddingBottom: "4.5%",
      lineHeight: "1.42857143",
      width: "100%",
      textAlign: "center",
      fontSize: "9px",
      color: " #8a8a8a",
      fontWeight: "400",
      fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',
      borderTop: '1.5px solid #113F74',
      marginTop:'1px',
      backgroundColor: `white`,
      "@media (max-width: 560px)": {
        paddingRight: '4%',
        paddingLeft: '4%',
        paddingTop: '6%'
      },

      
    },
    hiddenDisplay: {
      display: "none",
      paddingBottom: "5px !important", 
      paddingTop: "1px !important", 
      "@media (max-width: 450px)": {
        display: "block",
      },
    },
  };
};

export default buyHealthInsuranceOnlineStyle;
